
import { Component, Vue } from "vue-property-decorator";
import QuotationHeader from "@/components/Quotation/QuotationHeader.vue";
import * as DI from "@/utility/DependencyInjector";
import CustomTable from "@/components/Table/CustomTable.vue";
import WorkingCategory from "../../config/WorkingCategory.json";
import QuotationStatus from "../../config/QuotationStatus.json";
import DatepickerModal from "@/components/Modal/Util/DatepickerModal.vue";
import OrderStatusMap from "@/model/Mapping/OrderStatusMap";
import WorkingCategoryMap from "@/model/Mapping/WorkingCategoryMap";
import { convertDateToFormattedText } from "@/model/Date/Date";
// import AddComma from '@/model/MoneyFormat/AddComma.ts';

@Component({
  components: {
    QuotationHeader,
    CustomTable,
    DatepickerModal,
  },
})
export default class QuotationList extends Vue {
  //data
  queryRows = 0;
  totalRows = 0;
  isLogin = DI.get("App").auth.currentUser;
  QuotationStatusOption: { value: number; text: string }[] = [];
  workingCategoryOption: { value: string; text: string }[] = [];
  search: { [key: string]: any } = {
    status: undefined,
    workingCategoryId: undefined,
  };
  searchDateRange = "N/A";
  isTableBusy = false;
  statusMap = OrderStatusMap();
  WorkingCategoryMap = WorkingCategoryMap();
  orderData = [];
  orderField = [
    {
      key: "id",
      label: "訂單編號",
      thClass: "col-2",
    },
    {
      key: "workingCategoryId",
      label: "訂單工項",
      thClass: "col-3",
    },
    {
      key: "address",
      label: "叫修地址",
      thClass: "col-3",
    },
    {
      key: "createDate",
      label: "叫修日期與時間",
      thClass: "col-2",
    },
    {
      key: "status",
      label: "訂單狀態",
      thClass: "col-2",
    },
  ];

  //monted
  // hook
  async mounted(): Promise<void> {
    this.isTableBusy = true;
    if (!this.isLogin) {
      this.$router.push({
        path: "/",
      });
      this.$bvModal.show("quotation-login");
    }
    try {
      const res = await DI.get("App").repairOrder.repairOrderInProgressList();
      const uniqueWorkingCategoryIds = [
        ...new Set(
          res.map((item: { workingCategoryId: any }) => item.workingCategoryId)
        ),
      ];
      const result: any = uniqueWorkingCategoryIds.map((id) => {
        const matchingItem = res.find(
          (item: { workingCategoryId: unknown }) =>
            item.workingCategoryId === id
        );
        return {
          value: id,
          text: matchingItem ? matchingItem.workingCategoryName : "",
        };
      });
      this.workingCategoryOption = result;

      this.QuotationStatusOption = res
        .map((item: { status: number }) => item.status)
        .filter(function (element: any, index: Number, arr: Array<any>) {
          return arr.indexOf(element) === index;
        });
      this.orderData = res;
    } catch (error) {
      console.error(
        "VUE error:Failed to fetch repair order in progress list:",
        error
      );
    }
    this.isTableBusy = false;
  }
  //methods
  getDateRange(data: { start: string; end: string }): void {
    this.searchDateRange = convertDateToFormattedText(
      new Date(data.start),
      new Date(data.end)
    );
    this.search.createDate = data.start;
    this.search.endDate = data.end + " 23:59:59";
  }
  async onSearchClick(): Promise<void> {
    this.cleanObject(this.search);
    this.isTableBusy = true;
    try {
      const res = await DI.get("App").repairOrder.repairOrderInProgressList(
        this.search
      );
      this.orderData = res;
    } catch (error) {
      console.error(
        "VUE error:Failed to fetch repair order in progress list:",
        error
      );
      this.orderData = [];
    } finally {
      if (Object.keys(this.search).length === 0) {
        this.search = { status: undefined, workingCategoryId: undefined };
      }
      this.isTableBusy = false;
    }
  }

  cleanObject(obj: any): void {
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      if (!value) {
        delete obj[key];
      } else if (typeof value === "string") {
        obj[key] = value.trim();
      }
    });
    this.search = obj;
  }
  clearSearch(): void {
    this.search = { status: undefined, workingCategoryId: undefined };
    this.searchDateRange = "N/A";
    this.onSearchClick();
  }

  async onOrderClick(orderId: string): Promise<void> {
    await this.$router.push({
      path: "/Quotation/Info",
      query: {
        repairOrderId: orderId,
      },
    });
  }

  truncateAddressWithRegex(address: string): string {
    const roadTypes = ["路", "街", "道", "鄉", "市", "區"];
    let roadName = "";
    for (let type of roadTypes) {
      const regex = new RegExp(`.*[${type}]`);
      const match = address.match(regex);
      if (match) {
        roadName = match[0];
        break;
      }
    }
    return roadName ? roadName : address;
  }
  //移除重複物件text之內容 暫時不需要
  // removeDuplicateTextObjects(): any {
  //   const textSet = new Set<string>();
  //   return this.QuotationStatusOption.filter((obj: any) => {
  //     if (textSet.has(obj.text)) {
  //       return false;
  //     } else {
  //       textSet.add(obj.text);
  //       return true;
  //     }
  //   });
  // }
}

//computed
